import React from 'react';
import { RichText } from 'prismic-reactjs';

import ContactIndustryStats from '@/containers/ContactIndustry/ContactIndustryStats';
import ContactIndustryMain from '@/containers/ContactIndustry/ContactIndustryMain';

import {
  IndustryContactPageQuery,
  ShopContactPageQuery,
} from '../../../graphql-types';

interface Props {
  data: IndustryContactPageQuery | ShopContactPageQuery;
  type: string;
}

const ContactIndustryContainer = ({ data, type }: Props): JSX.Element => {
  // DATA

  const { page_title, page_description } = data?.prismicContactPage?.data || {};
  const companyInfos = data?.prismicCompanyInfosIndustry?.data || {};

  const companyStats = data?.allPrismicContactPageBodyCompanystats?.edges;
  // PROPS

  const MainProps = {
    title: <RichText render={page_title?.raw} />,
    description: <RichText render={page_description?.raw} />,
    companyInfos: {
      ...companyInfos,
      schedule: <RichText render={companyInfos?.schedule?.raw} />,
    },
  };

  const StatsProps = companyStats?.map((el) => {
    return {
      type: el?.node?.primary?.type,
      stats: el?.node?.items?.map((item) => ({
        number: <RichText render={item?.number?.raw} />,
        label: item?.label?.text,
      })),
    };
  });

  const renderContactStats = () => {
    return StatsProps?.map((stat) => {
      if (stat?.type === type) {
        return <ContactIndustryStats {...stat} />;
      }
      return <div />;
    });
  };

  return (
    <>
      <ContactIndustryMain {...MainProps} />
      {renderContactStats()}
    </>
  );
};

export default ContactIndustryContainer;
