import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import ContactIndustryContainer from '@/containers/ContactIndustry/ContactIndustryContainer';

import { IndustryContactPageQuery } from '../../../graphql-types';

interface Props {
  data: IndustryContactPageQuery;
}

export const pageQuery = graphql`
  query IndustryContactPage {
    prismicContactPage {
      data {
        meta_title
        meta_description
        ...ContactMain
      }
    }
    allPrismicContactPageBodyCompanystats {
      edges {
        node {
          primary {
            type
          }
          items {
            number {
              raw
            }
            label {
              text
            }
          }
        }
      }
    }
    prismicCompanyInfos {
      ...CompanyInfos
    }
    prismicCompanyInfosIndustry {
      ...CompanyInfosIndustry
    }
  }
`;

const Contact = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } = data?.prismicContactPage?.data || {};

  return (
    <Layout invertNav type="usine">
      <Seo
        title={meta_title || 'title'}
        description={meta_description || 'description'}
      />
      <ContactIndustryContainer data={data} type="usine" />
    </Layout>
  );
};

export default Contact;
